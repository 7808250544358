@import "primeng/resources/themes/nova/theme.css";
@import "primeng/resources/primeng.css";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&family=Poppins:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
}
:host ::ng-deep .dev-p-cal {
  padding: 24px !important;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* VARIABLES */
  --font-poppins: "Poppins", sans-serif;
  --font-jakarta: "Plus Jakarta Sans", sans-serif;
  --app-tb-bg-color: rgba(217, 217, 217, 1);
  --app-primary-1: #ff0000;
  --border-radius: 8px;
  --border-radius: 8px;
  --text-color-primary: #000000;
  --button-text-secondary: #000000;
  --button-bg-primary: #ff0000;
  --button-text-primary: white;
  --button-border-secondary: #d50707;
  --button-text-secondary: #000000;
  --bg-dark-1: #3e3e3e;
  --bg-dark-2: #cecece;
  --bg-light-1: #ffffff;
  --bg-light-2: #1e1e1e;

  --sidebar-width: 240px;
  --slidebar-width: min(460px, 100%);
  --slidebar-header-bg: rgba(154, 159, 191, 0.15);
  --slidebar-content-bg: #ffffff;
  --topbar-height: 60px;
  --faint-border-color: #e0e0e0;
  --info-bg: #f9f9f9;
  --info-border-color: #e3e3e3;
}

@media screen and (max-width: 1366px) {
  body {
    --sidebar-width: 200px;
    --slidebar-width: min(400px, 100%);
  }
}
// .p-datatable .p-datatable-tbody > tr{
//   background: var(--app-primary-1) !important;

.p-component {
  font-size: 0.75rem;
}

.p-button-outlined {
  background-color: transparent !important;
}

.p-button-outlined button {
  background-color: transparent !important;
}

button.outlined {
  background-color: transparent !important;
}

button:disabled,
.p-disabled {
  cursor: not-allowed !important;
}
#tbElem {
  height: 100% !important;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background: var(--app-primary-1) !important;
  color: black !important;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: var(--app-primary-1) !important;
  color: white !important;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--app-primary-1) !important;
  border-color: var(--app-primary-1) !important;
}
.app-text-primary-1 {
  color: var(--app-primary-1) !important;
}
.app-bg-primary-1 {
  background: var(--app-primary-1) !important;
}
.p-datatable .p-datatable-thead > tr > th {
  border: none !important;
  white-space: nowrap;
}
.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
  white-space: nowrap;
  // border-top: 1px solid #c8c8c8!important;
}
.p-treetable .p-treetable-tbody > tr > td {
  border: none !important;
  // border-top: 1px solid #c8c8c8!important;
}

.p-datatable .p-datatable-thead > tr > th {
  background: rgba(249, 249, 249, 1) !important ;
}
.p-treetable .p-treetable-thead > tr > th {
  border: none !important;
}
.p-datatable,
.p-treetable {
  // border: 1px solid white;
  background: white;
  border-radius: 8px;
  display: grid;
}
.p-confirm-dialog > .p-dialog-footer {
  text-align: center;
  display: flex;
  flex-direction: row;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  text-align: center;
  width: 100%;
}
.p-toast-message.p-toast-message-error {
  color: #970c0c !important;
  background: #faeaea !important;
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  filter: invert(30%) sepia(55%) saturate(2239%) hue-rotate(331deg)
    brightness(97%) contrast(101%);
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  filter: invert(61%) sepia(10%) saturate(2920%) hue-rotate(77deg)
    brightness(97%) contrast(87%);
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  filter: invert(75%) sepia(28%) saturate(6051%) hue-rotate(166deg)
    brightness(107%) contrast(96%);
}
.p-toast-message.p-toast-message-info {
  background: #def6fc !important;
  color: hsl(194, 93%, 35%) !important;
}
.p-toast-message.p-toast-message-success {
  background: #ebf4ec !important;
  color: #2d7738 !important;
}
.p-paginator-prev,
.p-paginator-next {
  display: none !important;
}

p-tooltip,
.p-tooltip-text {
  font-size: 14px !important;
  background: var(--app-primary-1) !important ;
  color: white !important;
  width: 120px;
  text-align: center;
}
.p-datatable .p-datatable-footer {
  border: none;
}
.p-toast {
  // left: var(--sidebar-width)!important;
  margin-left: 10px;
}
.p-confirm-dialog > .p-dialog-header {
  border-bottom: none;
  background: white;
  text-align: center;
}
.p-confirm-dialog > .p-dialog-footer {
  border-top: none;
  background: white;
}
.p-inputtext,
.p-dropdown,
.p-multiselect {
  border: 1.5px solid rgba(154, 159, 191, 0.2) !important;
  height: 2rem;
  border-radius: 8px;
  font-size: 0.75rem;
}

.form-field {
  .p-dropdown.p-component {
    width: 100%;
  }
}

.p-inputtext {
  // padding: 25px!important;
}
.p-inputtext:focus {
  outline: var(--app-primary-1);
}

.p-element.p-multiselect-label-container {
  display: flex;

  .p-multiselect-label {
    display: flex;
    align-items: center;
  }
}

.p-dropdown > .p-inputtext,
.p-multiselect > .p-inputtext {
  border: none !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--app-primary-1);
  border-radius: 20px;
}
.p-paginator {
  background: white;
  border: none;
  padding: 1em;
  justify-content: end !important;
}
.round-status {
  width: 120px;
  padding: 0.23rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border-radius: 20px;
  text-align: center;
}
.p-sidebar-right .p-sidebar-header {
  background: rgba(154, 159, 191, 0.15);
  height: 60px;
  margin-bottom: 2em;
}
.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.65rem;
  gap: 8px;
  font-weight: 500;
  font-size: 0.75rem;
  // padding: 1em !important;
  line-height: 24px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  border: 0;
}

.action-button:disabled {
  cursor: not-allowed;
}

.action-button.form-action-button {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 21px;
  width: 100%;
  padding: 12px;
}

.action-button.options {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 17px;
  padding: 0.55rem 1rem;
  gap: 24px;
  background-color: #f8f8f8;
  color: #ff0000;
}

.action-button:hover:not(:disabled) {
  opacity: 0.95;
}

.primary {
  background-color: var(--button-bg-primary);
  color: var(--button-text-primary);
}

.app-menu-icon {
  filter: brightness(0) saturate(100%) invert(15%) sepia(97%) saturate(7496%)
    hue-rotate(1deg) brightness(109%) contrast(112%);
}
.secondary {
  border: 1px solid var(--button-border-secondary);
  color: var(--button-text-secondary);
  background-color: transparent;
}

// There is some issue with primeNG pTemplate
// .p-sidebar-header,
// .p-sidebar-footer {
//   display: none !important;
//   visibility: none !important;
// }

// .p-sidebar .p-sidebar-content {
//   padding: 0 !important;
//   color: var(--text-color-primary);
// }

.p-sidebar-right {
  width: var(--slidebar-width) !important;
}
.slidebar-container {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  gap: 24px;
}

// .slidebar-header {
//   background-color: var(--slidebar-header-bg);
//   height: var(--topbar-height);
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   gap: 8px;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
// }

.slidebar-content {
  // overflow-y: auto;

  .form {
    display: grid;
    gap: 14px;
    padding: 4px;

    .form-field {
      display: grid;
      gap: 8px;
      font-size: 0.75rem;
      line-height: 21px;

      .p-fileupload-choose,
      .p-fileupload-choose:hover,
      .p-fileupload-choose:focus {
        background-color: transparent !important;
        font-weight: 500 !important;
        font-size: 1rem !important;
        line-height: 24px !important;
        color: #d50707 !important;
        border: 0;
      }

      .p-multiselect.p-multiselect-chip .p-multiselect-token {
        background-color: var(--button-bg-primary);
      }

      .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: var(--button-bg-primary);
      }

      label {
        font-weight: 500;
      }

      textarea,
      input {
        font-size: 1rem !important;
        font-weight: 400;
        // padding: 20px 8px;
        border: 1px solid var(--button-bg-primary);
        // height: 54px;
      }

      .p-dropdown {
        // padding: 0px 8px;
        vertical-align: middle;
      }

      .p-dropdown .p-dropdown-label {
        display: flex;
        align-items: center;
      }

      input,
      .p-inputnumber,
      .p-dropdown,
      .p-multiselect {
        background-color: transparent;
        width: 100%;
      }

      textarea {
        height: 160px;
        width: 100%;
      }
    }
  }

  .p-fileupload .p-fileupload-buttonbar {
    background-color: transparent;
  }
}

.import-export {
  .p-fileupload-choose,
  .p-fileupload-choose:not(.p-disabled):hover {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-jakarta);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 27px;
    text-align: center;
    color: #3e3e3e;
    border: 0;
    // max-width: 180px;
    // margin-inline: auto;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem !important;
}

.form-field > .p-inputtext:enabled:focus {
  border-color: var(--app-primary-1) !important;
  box-shadow: var(--app-primary-1) !important;
}
.form-field > .p-dropdown:enabled:focus {
  border-color: var(--app-primary-1) !important;
  box-shadow: var(--app-primary-1) !important;
}

.form-field > .p-inputtext:hover {
  box-shadow: var(--app-primary-1) !important;
}
.form-field > .p-dropdown:hover {
  box-shadow: var(--app-primary-1) !important;
}

.search-input {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 45px;
  border: 1px solid rgba(154, 159, 191, 0.15);
  border-radius: 8px;
  padding: 8px;

  input {
    outline: none;
    border: 0;
    background-color: transparent;
  }
}

.p-datatable-header {
  background-color: #ffffff !important;
  border: 0 !important;
}

.page-content {
  // padding: 12px;
}
.box-search {
  border: 1px solid rgba(154, 159, 191, 0.2);
  border-radius: 8px;
  padding: 4px;
}

.box-search-focus {
  border-color: var(--app-primary-1) !important;
  box-shadow: var(--app-primary-1) !important;
}
.box-search-blur {
  border-color: rgba(154, 159, 191, 0.2) !important;
  box-shadow: none !important;
}
.box-search > .p-inputtext:focus {
  box-shadow: var(--app-primary-1) !important;
}
.inactive-status {
  background: #ffdfdf;
  color: #eb5757;
}
.pendingApproval-status {
  background: gainsboro !important;
  color: var(--bluegray-500) !important;
}
.partnerstock-status {
  background: #edb2e8;
  color: #72066c;
}
.b2bstock-status {
  background: #dff305;
  color: #45570c;
}
.insuppport-status {
  background: #f37f93;
  color: #801818;
}
.cancelled-status {
  background: #720215;
  color: #ee8080;
}
.accountManager-status {
  background: #95f4fb;
  color: #186380;
}
.unasigned-status {
  background: #c8d5f0;
  color: #4174eb;
}
.eol-status {
  background: #ffdfdf;
  color: #e25151;
}
.inuse-status {
  background: #feeae0;
  color: #f98947;
}
.approving-status {
  background: #d8bbf8;
  color: #6635d7;
}
.error-response {
  color: #c40808;
}
.damaged-status {
  background: #f98181;
  color: #c40808;
}
.active-status {
  background: #d6f3e6;
  color: #2db76f;
}
.created-status {
  background: #aacbbc;
  color: #1c6640;
}
.disabled-status {
  background: #ffdfdf;
  color: #eb5757;
}
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #000000 !important;
}
.p-sidebar-left {
  width: var(--sidebar-width) !important;
  background-color: var(--bg-dark-1);
  height: 100%;
  display: flex;
  flex-direction: column;
  // gap: 2rem;
  padding: 2px;
  border: 0 !important;

  .logo-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // background-color: var(--bg-light-1);
    // padding: 16px;
  }

  ul {
    list-style: none;
    padding: 0;
    display: grid;
    gap: 4px;

    li {
      > a {
        color: #9f9f9f;
        text-decoration: inherit;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 12px;
      }

      > a:hover,
      a.active {
        background: linear-gradient(
            0deg,
            rgba(213, 161, 7, 0.05),
            rgba(213, 161, 7, 0.05)
          ),
          #ffffff;
        color: var(--app-primary-1);
        border-radius: 4px;
      }
    }
  }
}

.p-sidebar-right .p-sidebar-header .slidebar-heading {
}

.top-page-actions {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  align-items: center;

  .action-button {
    // padding: 12px;
  }
}

.p-sidebar-left .p-sidebar-footer {
  display: none !important;
  visibility: hidden !important;
}

@media screen and (max-width: 768px) {
  body {
    --topbar-height: 56px;
    // --slidebar-width: min(362px, 100%);
  }

  .page-content {
    // padding: 4px;
  }

  .p-datatable-header {
    padding-inline: 0rem !important;

    .table-top {
      display: grid !important;
      grid-template-columns: 65% 35% !important;
    }

    .table-top-with-date-search {
      display: grid !important;
      grid-template-columns: 65% 35% !important;

      .box-search {
        grid-column: span 2 / span 2;
      }
    }
  }

  .top-page-actions {
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 8px;

    .action-button {
      width: 100%;

      .p-button-label {
        flex: unset;
      }
    }
  }

  .p-sidebar-left {
    gap: 0;
  }

  .topbar-left.mobile,
  .topbar-center.mobile,
  .topbar-right.mobile {
    display: flex;
    visibility: visible;
    align-items: center;
    gap: 16px;
  }

  .topbar-left:not(.mobile),
  .topbar-right:not(.mobile) {
    display: none;
    visibility: hidden;
  }
}

.p-dropdown-open .p-overlay {
  max-width: 100% !important;
  min-width: none !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--button-bg-primary);
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: var(--button-bg-primary);
}